.sidebar {
	width: 25rem;
	background: $accent-color;
	padding: 3.2rem 1.6rem;
	position: fixed;
	bottom: 0;
	top: 0;
	left: 0;
	@include trans;
}
.publicSidebar {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.hiddenSidebar {
	left: -25rem;
}
// Header
.sidebar__header {
	margin-bottom: 4rem;
}
.sidebar__header--title {
	color: $white;
	font-weight: $fw-light;
	text-align: center;
}
.sidebar__header--logo {
	background: url('/images/logo.png') no-repeat center center / cover;
	margin: 0 auto;
	margin-bottom: 3.2rem;
	height: 13rem;
	width: 10rem;
}
// Links
.sidebar__link {
	display: flex;
	color: $dark-border;
	padding: 1.6rem 0;
	position: relative;
	&:hover .sidebar__link--left {
		color: $white;
	}
	&:hover .sidebar__link--right {
		color: $white;
		right: -8px;
	}
	&.active {
		color: $white;
	}
}
.sidebar__link--right {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 0;
	@include trans;
}
.sidebar__link--left {
	@include trans;
	span {
		margin-left: 0.8rem;
	}
}
// Logout
.sidebar__logoutBtn {
	position: absolute;
	bottom: 3.2rem;
	left: 0;
	color: $dark-border;
	font-size: 1.6rem;
	padding: 1.6rem 0;
	border-top: 1px solid #2e3553;
	border-bottom: 1px solid #2e3553;
	width: 100%;
	@include trans;
	&:hover {
		color: $white;
	}
}

// Toggle
.sidebar__toggle--text {
	@include trans;
	margin-left: 0.5rem;
	opacity: 0;
	font-size: $p-small-desktop;
	font-weight: $fw-normal;
}
.sidebar__toggle--icon {
	@include trans;
	color: $faded-text;
}
.sidebar__toggle {
	position: absolute;
	left: 26rem;
	top: 1.5rem;
	font-size: 1.4rem;
	&:hover > .sidebar__toggle--icon {
		color: $text-color;
	}
	&:hover > .sidebar__toggle--text {
		opacity: 1;
	}
}
