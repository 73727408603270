.alert {
	position: fixed;
	min-width: 30vw;
	left: 4rem;
	top: 4rem;
	padding: 0.8rem 1.3rem;
	border-radius: 0.5rem;
	font-weight: 300;
	box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.9);
	animation: slideInAndLeave 2.5s ease-in-out forwards;
	z-index: 100;
	&.success {
		color: $white;
		background: $accent-color;
		border-color: darken($accent-color, 15%);
	}
	&.error {
		background: $delete-color;
		border-color: darken($delete-color, 15%);
		color: $white;
	}
}
@keyframes slideInAndLeave {
	0% {
		top: -5rem;
	}
	15% {
		top: 4rem;
	}
	80% {
		top: 4rem;
	}
	90% {
		top: 7.8rem;
	}
	100% {
		top: -9rem;
	}
}
