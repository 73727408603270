.singleQuestion {
	background: $white;
	border-radius: 0.4rem;
	border: 1px solid $light-border;
	margin-bottom: 2.4rem;
	padding: 2.4rem 1.6rem 1.6rem 1.6rem;
	word-wrap: break-word;
}
.singleQuestion__section {
	margin-bottom: 2.4rem;
}
.singleQuestion__label {
	color: $icon-color;
	font-weight: $fw-light;
	margin-bottom: 0.8rem;
}
.singleQuestion__buttons {
	button:first-child {
		margin-right: 1.6rem;
		@include mq('extra-small') {
			margin-right: 0;
		}
	}
	@include mq('extra-small') {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
}
