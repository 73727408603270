.buttonElement {
	background: rgba(0, 0, 0, 0);
	border-radius: 0;
	border: 0;
	outline: 0;
	margin: 0;
	padding: 0;
	font-size: $p-desktop;
	@include mq('extra-small') {
		font-size: $p-mobile;
	}

	.buttonElement__icon {
		margin-right: 0.5rem;
	}

	&.whiteButton {
		background: $white;
		padding: 1rem 4rem;
		border-radius: 0.4rem;
		font-weight: $fw-light;
		@include trans;
		&:hover {
			background: darken($white, 15%);
		}
	}
	&.blueButton {
		background: $accent-color;
		color: $white;
		padding: 1rem 4rem;
		border-radius: 0.4rem;
		font-weight: $fw-light;
		@include trans;
		&:hover {
			background: lighten($accent-color, 3%);
		}
		@include mq('extra-small') {
			padding: 1rem 3.5rem;
		}
	}
	&.greyButton {
		background: #cbcbcb;
		color: $text-color;
		padding: 1rem 4rem;
		border-radius: 0.4rem;
		font-weight: $fw-light;
		@include trans;
		&:hover {
			background: lighten(#cbcbcb, 3%);
		}
		@include mq('extra-small') {
			padding: 1rem 3.5rem;
		}
	}
	&.textLink {
		color: $icon-color;
		&.faded {
			color: $faded-text;
		}
		@include trans;
		&:hover {
			&.hoverAccent {
				color: $accent-color;
			}
			&.hoverEdit {
				color: $edit-color;
			}
			&.hoverDelete {
				color: $delete-color;
			}
			&.hoverBlack {
				color: $text-color;
			}
		}
	}
	&.addBtn {
		color: $faded-text;
		margin-top: 4rem;
		font-size: 1.5rem;
		&:hover {
			color: $text-color;
		}
	}
}
.saveCancelButtons {
	@include mq('extra-small') {
		text-align: center;
	}
}
