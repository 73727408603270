.singlePhrase {
	width: 100%;
	background: $white;
	border: 1px solid $light-border;
	border-radius: 0.4rem;
	padding: 1.6rem;
	word-wrap: break-word;
	&:not(last-child) {
		margin-bottom: 1.6rem;
	}
}
.singlePhrase__text {
	margin-bottom: 1.6rem;
	font-size: $p-desktop;
}
.singlePhrase__buttons {
	button {
		font-size: 1.4rem !important;
	}
	button:first-child {
		margin-right: 1.6rem;
	}
	@include mq('extra-small') {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-top: 2.4rem;
	}
}
