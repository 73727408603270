.header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 3.2rem;
}
.header__title {
	@include mq('medium') {
		font-size: $h1-tablet;
	}
	@include mq('extra-small') {
		font-size: $h1-mobile;
	}
}
.header__backBtn {
	color: $faded-text;
	&:hover {
		color: $text-color;
	}
	&:hover .header__backBtn--icon {
		margin-right: 1.6rem;
	}
	@include mq('small') {
		white-space: nowrap;
	}
}
.header__backBtn--text {
	@include trans;
	@include mq('small') {
		white-space: nowrap;
	}
}
.header__backBtn--icon {
	@include trans;
	margin-right: 0.8rem;
}
