.singleUser {
	background: $white;
	border-radius: 0.4rem;
	border: 1px solid $light-border;
	padding: 1.6rem;
	display: flex;
	justify-content: space-between;
	word-wrap: break-word;
	&:not(:last-child) {
		margin-bottom: 1.6rem;
	}
	@include mq('small') {
		flex-direction: column;
	}
}
.singleUser__rightSide {
	display: flex;
	@include mq('small') {
		justify-content: space-between;
		margin-top: 2.4rem;
		flex-direction: row-reverse;
	}
}
.singleUser__premium {
	color: $icon-color;
	margin-right: 1.6rem;
	em {
		color: $text-color;
	}
	@include mq('small') {
		margin-right: 0;
		font-size: $p-mobile;
	}
}
