// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

// Regular font family
$main-font: 'Ubuntu', sans-serif;

// Code (monospace) font family
$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono',
	'Monaco', monospace !default;

/// Font Sizes - Desktop
$h1-desktop: 4rem;
$h2-desktop: 3.2rem;
$h3-desktop: 2.8rem;
$h4-desktop: 2.4rem;
$h5-desktop: 2rem;
$h6-desktop: 1.6rem;
$p-desktop: 1.6rem;
$p-small-desktop: 1.2rem;

// Font Sizes - Tablet
$h1-tablet: 4rem;
$h2-tablet: 2.8rem;
$h3-tablet: 2.4rem;
$h4-tablet: 2rem;
$h5-tablet: 1.6rem;
$h6-tablet: 1.4rem;

// Font Sizes - Phone
$h1-mobile: 3.2rem;
$h2-mobile: 2.4rem;
$h3-mobile: 2rem;
$h4-mobile: 1.6rem;
$h5-mobile: 1.4rem;
$h6-mobile: 1.2rem;
$p-mobile: 1.5rem;
$p-small-mobile: 1.2rem;

// Font weights
$fw-light: 300;
$fw-normal: 400;
$fw-medium: 500;
$fw-bold: 800;

/// Colors
$transparent: rgba(0, 0, 0, 0);
$white: #ffffff;
$black: #000000;
$text-color: #252525;
$dark-border: #5a607c;
$light-border: #d7e0e7;
$icon-color: #b0cddc;
$accent-color: #0b1542;
$bg-color: #eef1f3;
$faded-text: #a4b0b6;
$delete-color: #ab0d0d;
$edit-color: #037840;

/// Margins / Paddings
$nav-padding: 16px 40px;

/// Container's maximum width
$max-width: 1600px !default;

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
	'extra-small': 500px,
	'small': 768px,
	'medium': 992px,
	'large': 1024px,
	'extra-large': 1200px,
) !default;

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
/// $base-url: 'http://domain.com/assets/';
$base-url: '/assets/' !default;
