.mainLayout {
	background: $bg-color;
	min-height: 100vh;
	display: flex;
}

.contentContainer {
	min-height: 100vh;
	margin-left: 25rem;
	padding: 5rem;
	width: 100%;
	@include trans;
	@include mq('medium') {
		padding: 2.4rem;
		padding-top: 5rem;
	}
	@include mq('extra-small') {
		padding: 1.6rem;
		padding-top: 5rem;
	}
}
