.deleteModal {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}
.deleteModal__backdrop {
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	position: relative;
}
.deleteModal__window {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: $white;
	padding: 5rem 4rem;
	border-radius: 0.4rem;
	max-width: 350px;
	animation: smallBounce 0.3s ease-in-out forwards;
	@include mq('small') {
		padding: 4rem 2.4rem 3.2rem 2.4rem;
	}
	@include mq('extra-small') {
		padding: 4rem 2.4rem 2.4rem 2.4rem;
	}
}
@keyframes smallBounce {
	0% {
		transform: translate(-50%, -50%) scale(1);
	}
	50% {
		transform: translate(-50%, -50%) scale(1.1);
	}
	100% {
		transform: translate(-50%, -50%) scale(1);
	}
}
.deleteModal__window--closeIcon {
	position: absolute;
	top: 1rem;
	right: 1.5rem;
	color: $dark-border;
	@include trans;
	&:hover {
		color: $text-color;
		cursor: pointer;
	}
}
.deleteModal__window--text {
	text-align: center;
	font-size: $h5-desktop;
	line-height: $h4-desktop;
	em {
		color: $delete-color;
	}
	@include mq('small') {
		margin-right: 0;
		font-size: $h4-tablet;
		line-height: $h4-tablet;
	}
}
.deleteModal__window--buttons {
	text-align: center;
	margin-top: 2.4rem;
	button:first-child {
		margin-right: 1.6rem;
		@include mq('small') {
			margin-right: 1.2rem;
		}
	}
	@include mq('small') {
		margin-right: 0;
	}
}
