.loginPage {
	background: $accent-color;
	color: $white;
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding-bottom: 16rem;
	padding-top: 4rem;
}
.loginPage__container {
	max-width: 400px;
}
.loginPage__header {
	text-align: center;
	margin-bottom: 4rem;
}
.loginPage__header--logo {
	height: 20rem;
	width: 16rem;
	background: url('/images/logo.png') no-repeat center center / cover;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 2.4rem;
}
.loginPage__header--description {
	font-size: $h5-desktop;
	max-width: 400px;
	margin: 0 auto;
	font-weight: $fw-light;
	margin-top: 1.6rem;
}
