.singleCategory {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: $white;
	border: 1px solid $light-border;
	border-radius: 0.6rem;
	padding: 1.6rem 2.4rem;
	margin-bottom: 1.6rem;
	word-wrap: break-word;
	@include mq('extra-small') {
		flex-direction: column;
		padding-right: 1rem;
		padding-left: 1rem;
		text-align: left !important;
	}
}
.singleCategory__title {
	@include mq('extra-small') {
		width: 100%;
		text-align: left;
	}
}
.singleCategory__rightSide {
	button:not(:last-child) {
		margin-right: 2.4rem;
	}
	@include mq('extra-small') {
		margin-top: 2.4rem;
		margin-right: 0;
		width: 100%;
		display: flex;
		justify-content: space-between;
		button {
			margin-right: 0 !important;
		}
	}
}
