.formElement {
	margin-bottom: 2.4rem;
}
.inputElement {
	display: block;
	width: 100%;
	border: 1px solid $light-border;
	border-radius: 0.4rem;
	color: $text-color;
	padding: 1rem 1.6rem;
	&::placeholder {
		color: $faded-text;
		font-weight: $fw-light;
	}
}
.inputElement__label {
	display: block;
	margin-bottom: 0.8rem;
}
.inputElement__error {
	display: block;
	margin-top: 0.5rem;
	font-size: 1.4rem;
	color: lighten($delete-color, 8%);
}
