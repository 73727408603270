// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
	box-sizing: border-box;
}
body {
	min-height: 100vh;
	position: relative;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
	box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
	text-decoration: none;
	font-size: $p-small-desktop;
	color: $black;
	outline: none;
	&:hover,
	&:active,
	&:focus {
		outline: none;
	}
}
button {
	background: rgba(0, 0, 0, 0);
	border-radius: 0;
	border: 0;
	outline: 0;
}
h3 {
	line-height: 1.2em;
}
