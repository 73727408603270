.userProfile {
	background: $white;
	border: 1px solid $light-border;
	border-radius: 0.4rem;
	padding: 2.4rem;
}
.userProfile__section {
	margin-bottom: 3.2rem;
}
.userProfile__sectionTitle {
	text-transform: uppercase;
	font-weight: $fw-light;
	color: $text-color;
	margin-bottom: 1.6rem;
}
.userProfile__fieldContainer {
	margin-bottom: 2rem;
}
.userProfile__fieldLabel {
	color: $accent-color;
	margin-bottom: 0.8rem;
}
.userProfile__buttons {
	button:first-child {
		margin-right: 1.6rem;
	}
}
