.textarea__wrapper {
	margin-top: 2.4rem;
}
.textarea__label {
	display: block;
	margin-bottom: 0.8rem;
}
.textarea {
	width: 100%;
	border: 1px solid $light-border;
	border-radius: 0.4rem;
	padding: 1.2rem 1.6rem;
	min-height: 17rem;
	&::placeholder {
		color: $faded-text;
		font-weight: $fw-light;
	}
}
