.dataCard {
	background: $white;
	width: 20%;
	padding: 2.4rem 1.6rem;
	border: 1px solid $light-border;
	border-radius: 0.4rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	&:not(:last-child) {
		margin-right: 1.6rem;
	}
	@include mq('extra-large') {
		width: calc(50% - 1.6rem);
		margin-right: 1.6rem;
		&:nth-child(n + 1) {
			margin-right: 0;
		}
		&:not(:last-child) {
			margin-bottom: 1.6rem;
		}
	}
	@include mq('small') {
		width: 100%;
		margin-right: 0;
		margin-bottom: 1.6rem;
		&:last-child() {
			margin-bottom: 0;
		}
	}
}
.dataCard__info--label {
	color: $icon-color;
	margin-bottom: 0.8rem;
}
.dataCard__info--data {
	font-size: $h4-desktop;
}
.dataCard__icon {
	color: $icon-color;
	font-size: $h1-desktop;
}
