.privacyPolicy {
	h1 {
		margin-bottom: 1.6rem;
	}
	p {
		font-weight: 300;
		margin-bottom: 1.4rem;
	}
}
.privacyPolicy__heading {
	font-weight: 500 !important;
	margin-top: 2.4rem;
}
.privacyPolicy__title {
	font-size: 2.4rem;
}
