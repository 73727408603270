.searchUsers {
	margin-bottom: 3.2rem;
}
.searchUsers__input {
	padding: 1.6rem 2.4rem;
	width: 100%;
	border-radius: 0.4rem;
	border: 1px solid $light-border;
	background: rgba(0, 0, 0, 0);
	&::placeholder {
		font-weight: $fw-light;
		color: $faded-text;
	}
}
