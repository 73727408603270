.loadingSpinner {
	background: url('/images/spinner.gif') no-repeat center center / cover;
	position: absolute;
	width: 50px;
	height: 50px;
	top: 50vh;
	left: 50vw;
	transform: translate(-50%, -50%);
	overflow: hidden !important;
	z-index: 100;
}
